import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Hero from '@components/Hero';
import AboutUs from '@components/AboutUs';
import Team from '@components/Team';
import Services from '@components/Services';
import Contact from '@components/Contact';
import Slideshow from '@components/Slideshow';
import Booking from '@components/Booking';

const LocationPage = ({
  pageContext: { pageName },
  data: {
    contentfulHero: hero,
    contentfulAboutUs: aboutUs,
    contentfulTeam: team,
    contentfulServices: services,
    contentfulContact: contact,
    contentfulSlideshow: slideshow,
  },
}) => (
  <Main>
    <Hero {...hero} />
    <AboutUs {...aboutUs} />
    <Team {...team} />
    <Slideshow {...slideshow} />
    <Services {...services} />
    <Contact {...contact} pageName={pageName} />
    {pageName === 'Maximilianstraße' && (
      <Booking number="004994155300" text="0941 55 300" onlineBooking />
    )}
    {pageName === 'Sternbergstraße' && <Booking number="004994151101" text="0941 511 01" />}
  </Main>
);

const Main = styled.main``;

export const homepageQuery = graphql`
  query LocationQuery($location: String!) {
    contentfulHero(location: { eq: $location }) {
      title
      image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          quality: 75
        )
      }
    }
    contentfulAboutUs(location: { eq: $location }) {
      title
      textVisible {
        raw
      }
      textHidden {
        raw
      }
      name
      role
      image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
          layout: CONSTRAINED
          width: 1536
          quality: 75
        )
      }
    }
    contentfulTeam(location: { eq: $location }) {
      title
      teamMembers {
        name
        role
        image {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 1536
            quality: 75
            aspectRatio: 1
          )
        }
      }
    }
    contentfulServices(location: { eq: $location }) {
      title
      services {
        title
        shortTitle
        textShort {
          raw
        }
        textBlocks {
          textVisible {
            raw
          }
          textHidden {
            raw
          }
        }
        image {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 1536
            quality: 75
            aspectRatio: 1
          )
        }
      }
    }
    contentfulContact(location: { eq: $location }) {
      title
      address {
        raw
      }
      telephone
      fax
      email
      openingTimes {
        raw
      }
      image {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          quality: 75
        )
      }
    }
    contentfulSlideshow(location: { eq: $location }) {
      images {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
          formats: [AUTO, WEBP, AVIF]
          aspectRatio: 1.5
          resizingBehavior: FILL
          cropFocus: FACES
          quality: 75
        )
      }
    }
  }
`;

export default LocationPage;
